import request from '@/utils/request'

// api地址
const api = {
  info: '/frm/activityInfo/',
  all: '/frm/activityInfo',
  save: '/frm/activityInfo',
  update: '/frm/activityInfo/update',
  delete: '/frm/activityInfo/',
  deleteBatch: '/frm/activityInfo/batch',
  page: '/frm/activityInfo/page',
  getContent: '/frm/activityInfo/content/',
  saveContent: '/frm/activityInfo/content',
  getNotice: '/frm/activityInfo/notice/',
  saveNotice: '/frm/activityInfo/notice',
  getRecord: '/frm/activityInfo/record/',
  saveRecord: '/frm/activityInfo/record',
  articleHtml: '/frm/activityInfo/record/url'
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getContentById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.getContent + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function saveContent(data) {
  return new Promise((resolve, reject) => {
    request.post(api.saveContent, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getNoticeById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.getNotice + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function saveNotice(data) {
  return new Promise((resolve, reject) => {
    request.post(api.saveNotice, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getRecordById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.getRecord + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function saveRecord(data) {
  return new Promise((resolve, reject) => {
    request.post(api.saveRecord, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getArticleHtml(data) {
  return new Promise((resolve, reject) => {
    request.post(api.articleHtml, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}