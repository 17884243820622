<template>
  <a-card :bordered="false">
    <a-form :model="where" :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }">
      <a-row>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <a-form-item label="姓名:">
            <a-input v-model:value.trim="where.name" placeholder="请输入姓名" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <a-form-item label="手机号:">
            <a-input v-model:value.trim="where.phone" placeholder="请输入手机号" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <a-form-item label="签到状态:">
            <a-select
              v-model:value="where.state"
              placeholder="请选择签到状态"
              allow-clear
            >
              <a-select-option
                v-for="item in signStateList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <!-- <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="状态:">
            <a-select
              v-model:value="where.state"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option
                v-for="item in regStateList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="付款状态:">
            <a-select
              v-model:value="where.payState"
              placeholder="请选择付款状态"
              allow-clear
            >
              <a-select-option
                v-for="item in payStateList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col> -->
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- <div>
      <barEcharts></barEcharts>
    </div> -->
    <div>
      <ud-pro-table ref="tableReg" row-key="id" :datasource="datasource" :columns="columns"
        :scroll="{ x: 'max-content' }">
        <!-- <template #pic="{ record }">
          <div class="image">
            <a-image :src="record.corpPic" :width="120" :height="90"></a-image>
          </div>
        </template> -->
        <template #toolbar>
          <a-space>
            <a-button type="danger" @click="openSignSetting">设置</a-button>
            <a-button type="primary" @click="exportUserSign">导出</a-button>
            <a-button type="info" @click="createUserSign">生成签到表</a-button>
            <a-button type="info" @click="uploadUserSign">导入活动安排</a-button>
            <a-button type="info" @click="downloadWXACode">下载签到表二维码</a-button>
            <!-- <a-button type="info" @click="showGroup = true">生成组</a-button>-->
          </a-space>
        </template>
      </ud-pro-table>
    </div>
    <group v-model:visible="showGroup" :data="groupData" />
    <guest v-model:visible="showGuest" />
    <sign-import v-model:visible="showImport" v-model:activityId="id" @done="reload" />
  </a-card>
  <a-modal :width="1000" v-model:visible="showSignSetting" :confirm-loading="loading" :footer="null">
    <!-- 费用信息弹窗 -->
    <sign-setting @done="doneSignSetting" :activityId="activityId" :formId="formId" />
  </a-modal>
</template>

<script>
import * as activityUserSignApi from '@/api/activity/activityUserSign.js'
import * as dictApi from '@/api/dict/dictData.js'
import group from './group.vue'
import guest from './guest.vue'
import { toDateString } from '@/utils/util.js'
import signImport from './sign-import.vue'
import signSetting from './setting.vue'
export default {
  emits: ['done', 'update:visible'],
  props: {
    activityId: Number
  },
  components: {
    group,
    guest,
    signImport,
    signSetting
  },
  watch: {
    activityId() {
      if (this.activityId) {
        this.reload()
      }
    }
  },
  data() {
    return {
      name: 'grouptList',
      showGroup: false,
      showGuest: false,
      showImport: false,
      groupData: [],
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tableReg.tableIndex + index
        },
        {
          title: '姓名',
          dataIndex: 'name',
          sorter: true
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          sorter: true
        },
        {
          title: '性别',
          dataIndex: 'sex',
          sorter: true
        },
        {
          title: '车辆安排',
          dataIndex: 'carInfo',
          sorter: true
        },
        {
          title: '座位安排',
          dataIndex: 'placeInfo',
          sorter: true
        },
        {
          title: '签到时间',
          dataIndex: 'signTime',
          sorter: true
        }
      ],
      // 表格搜索条件
      where: {},
      signStateList: [],
      payStateList: [],
      id: this.activityId,
      showSignSetting: false
    }
  },
  mounted() {
    this.querySignState()
    this.queryPayState()
  },
  methods: {
    queryGroupData() {
      activityUserSignApi
        .all({
          activityId: this.activityId,
          order: 'desc',
          sort: 'createTime'
        })
        .then((res) => {
          if (res.code === 0) {
            this.groupData = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    datasource(option, callback) {
      activityUserSignApi
        .page({
          ...this.where,
          activityId: this.activityId,
          order: 'desc',
          sort: 'createTime',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.$refs.tableReg.reload({ page: 1 })
    },
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 查询报名状态
    querySignState() {
      dictApi
        .getDictData({ dictCode: 'signState' })
        .then((res) => {
          if (res.code === 0) {
            this.signStateList = res.data
          } else {
            this.$message.error(res.data)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询支付状态
    queryPayState() {
      dictApi
        .getDictData({ dictCode: 'payState' })
        .then((res) => {
          if (res.code === 0) {
            this.payStateList = res.data
          } else {
            this.$message.error(res.data)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    createUserSign() {
      activityUserSignApi
        .createUserSign(this.activityId)
        .then((res) => {
          if (res.code === 0) {
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    exportUserSign() {
      activityUserSignApi
        .exportActivityUserSign(this.activityId, {
          ...this.where
        })
        .then((res) => {
          const { data, headers } = res
          const fileName = '签到信息-' + toDateString(new Date(), 'MMddHHmmss') + '.xlsx' // headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
          // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
          // const blob = new Blob([JSON.stringify(data)], ...)
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => {
        })
    },
    downloadWXACode() {
      activityUserSignApi
        .downloadWXACode(this.activityId)
        .then((res) => {
          const { data, headers } = res
          const fileName = '签到二维码-' + toDateString(new Date(), 'MMddHHmmss') + '.jpg' // headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
          // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
          // const blob = new Blob([JSON.stringify(data)], ...)
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => {
        })
    },
    uploadUserSign() {
      this.showImport = true
    },
    openSignSetting() {
      this.showSignSetting = true
    },
    doneSignSetting() {
      this.showSignSetting = false
    }
  }
}
</script>
<style  scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}

:deep(.ud-tool) {
  display: none;
}
</style>
