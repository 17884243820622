<template>
  <div>
    <a-modal
      :width="700"
      :visible="visible"
      :confirm-loading="loading"
      title="生成组"
      @update:visible="updateVisible"
      :body-style="{ paddingBottom: '8px' }"
    >
      <template #footer>
        <a-space>
          <a-button key="cancel" @click="handleCancel">取消</a-button>
          <a-button key="create" @click="createGroup">生成组</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="handleOk"
            >确定</a-button
          >
        </a-space>
      </template>
      <a-row>
        <a-col :sm="24" :xs="24">
          <a-form
            :model="authorForm"
            :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
            :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
          >
            <a-form-item label="人员分" name="manageId">
              <a-select
                v-model:value="form.type"
                showArrow
                placeholder="请选择分组类型"
              >
                <a-select-option
                  v-for="(item, index) in options"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="分组字段" name="sort">
              <a-select
                v-model:value="form.sort"
                showArrow
                placeholder="请选择分组字段"
              >
                <a-select-option
                  v-for="(item, index) in options2"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="分组数量" name="count">
              <a-input-number
                v-model:value="form.count"
                placeholder="请输入分组数量"
                allow-clear
                style="width: 100%"
              ></a-input-number>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
      <div class="group-wrap">
        <a-row :gutter="[16, 8]">
          <a-col
            :sm="12"
            :xs="24"
            v-for="(item, index) in groupList"
            :key="index"
          >
            <div class="group" :class="item.expand ? 'group-active' : ''">
              <div class="icon">
                <span class="title">{{ item.code }}</span
                ><down-outlined v-show="item.expand" @click="expandAll(item)" />
                <up-outlined @click="expandAll(item)" v-show="!item.expand" />
              </div>
              <div
                v-for="(item2, index2) in item.group"
                :key="index2"
                class="group-item"
              >
                <div :class="item2.expand ? 'item-active' : ''">
                  <div>
                    姓名: <span class="value">{{ item2.name }}</span>
                  </div>
                  <div>
                    手机号: <span class="value">{{ item2.phone }}</span>
                  </div>
                  <div>
                    退役年度: <span class="value">{{ item2.year }}</span>
                  </div>
                </div>
                <div class="right-wrap">
                  <a-input class="input" v-model:value="item2.code"></a-input>
                  <down-outlined
                    v-show="item2.expand"
                    @click="expandAll(item2)"
                  />
                  <up-outlined
                    @click="expandAll(item2)"
                    v-show="!item2.expand"
                  />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
export default {
  props: {
    data: {
      type: Array
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DownOutlined,
    UpOutlined
  },
  watch: {
    data() {
      if (this.data) {
        this.groupData = this.data
      }
    }
  },
  data() {
    return {
      groupData: [],
      groupList: [
        {
          code: 'A桌',
          expand: false,
          group: [
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌01'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌01'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌01'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌01'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌01'
            }
          ]
        },
        {
          code: 'B桌',
          expand: false,
          group: [
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'B桌02'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'B桌02'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌02'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌02'
            },
            {
              expand: false,
              name: 'gwj',
              phone: '18370092688',
              year: '2006',
              code: 'A桌02'
            }
          ]
        }
      ],
      isUpdate: false,
      // 提交状态
      loading: false,
      form: {},
      options: [
        { value: 'A', label: '桌' },
        { value: 'B', label: '车' },
        { value: 'C', label: '组' }
      ],
      options2: [
        { value: 'A', label: '按性别分' },
        { value: 'B', label: '按退役年度分' }
      ]
      // test: [
      //   {
      //     name: 'z',
      //     value: '1'
      //   },
      //   {
      //     name: 'b',
      //     value: '1'
      //   },
      //   {
      //     name: 'z',
      //     value: '1'
      //   },
      //   {
      //     name: 'b',
      //     value: '1'
      //   }
      // ]
    }
  },
  methods: {
    expandAll(item, index) {
      item.expand = !item.expand
    },
    handleCancel() {
      this.updateVisible(false)
    },
    // dataGroupingToArray(array, key, resKey) {
    //   if (!key) return array
    //   var aMap = []
    //   var aResult = []
    //   for (var i = 0; i < array.length; i++) {
    //     var item = array[i]
    //     if (aMap.indexOf(item[key]) === -1) {
    //       var oItem = {}
    //       oItem[resKey] = [item]
    //       oItem[key] = item[key]
    //       aResult.push(oItem)
    //       aMap.push(item[key])
    //     } else {
    //       var index = aMap.indexOf(item[key])
    //       aResult[index][resKey].push(item)
    //     }
    //   }
    //   return aResult
    // },
    createGroup() {
      // const arr = this.dataGroupingToArray(this.test, 'name', 'group')

      // console.log('arr', arr)
    },
    handleOk() {},
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
.group-wrap {
  max-height: 478px;
  overflow: hidden;
  overflow-y: auto;
}
.group-active {
  height: 63px;
  overflow: hidden;
  user-select: none;
}
.group {
  border: 1px solid #d6d7d9;
  padding: 20px;
  transition: all 0.5s linear;
  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 18px;
    }
  }
  & .group-item:last-child {
    border: none;
  }
}
.group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d6d7d9;
  padding: 10px 0;
  .item-active {
    height: 44px;
    overflow: hidden;
  }
  .right-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .value {
    margin-left: 10px;
    color: #9b9b9b;
  }
  .input {
    width: 65px;
    margin-right: 10px;
  }
}
</style>
