<template>
  <a-modal
    :width="500"
    :visible="visible"
    title="模板导入"
    @update:visible="updateVisible"
    @cancel="updateVisible(false)"
    @ok="exportTem"
  >
    <a-form
      :model="where"
      :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24" :xs="24">
          <a-form-item label="表单名称:">
            <a-input
              v-model:value.trim="where.formName"
              placeholder="请输入表单名称"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <ud-pro-table
      :toolbar="false"
      ref="tables"
      selectionType="radio"
      row-key="formId"
      :datasource="datasource"
      :columns="columns"
      :customRow="customRowClick"
      v-model:current="selection"
      :scroll="{ x: 'max-content' }"
    >
    </ud-pro-table>
  </a-modal>
</template>

<script>
import * as activityFormApi from '@/api/activity/activityForm.js'
import * as activityFormFieldApi from '@/api/activity/activityFormField.js'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    formId() {
      this.$nextTick(() => {
        this.selection = {}
      })
    }
  },
  data() {
    return {
      where: {},
      selection: {},
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tables.tableIndex + index
        },
        {
          title: '模板名称',
          dataIndex: 'formName'
        }
      ]
    }
  },
  methods: {
    customRowClick(record) {
      const that = this
      return {
        onclick() {
          that.selection = record
          console.log('record', record, that.selection)
        }
      }
    },
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    datasource(option, callback) {
      activityFormApi
        .page({
          ...this.where,
          order: 'desc',
          sort: 'createTime',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = {}
      this.$refs.tables.reload({ page: 1 })
    },
    // /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    exportTem() {
      const params = {
        sort: 'sortNumber',
        order: 'asc',
        limit: 1000,
        page: 1,
        formId: this.selection.formId
      }
      activityFormFieldApi
        .page(params)
        .then((res) => {
          if (res.code === 0) {
            this.updateVisible(false)
            this.$emit('done', res.data)
          } else {
            this.$error(res.msg)
          }
        })
        .catch((error) => {
          this.$error(error.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
