<!-- 编辑弹窗 -->
<template>
    <a-modal title="活动记录" :width="950" :confirm-loading="loading" :visible="visible" @update:visible="updateVisible" @ok="save">
        <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
            :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }">
            <a-row :gutter="16">
                <a-col :sm="24" :xs="24">
                    <a-form-item label="文章地址:" name="articleUrl" :labelCol="{ span: 1.5, offset: 1 }"
                        :wrapper-col="{ span: 24, offset: 1.5 }" layout="inline">
                        <a-input-search v-model:value="form.articleUrl" placeholder="请输入微信公众号文章地址" allow-clear
                            @search="getArticleHtml">
                            <template #enterButton>
                                <a-button type="primary" :loading="loading2"> 抓取</a-button>
                            </template>
                        </a-input-search>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :sm="24" :xs="24">
                    <a-form-item label="活动记录" :labelCol="{ span: 1.5, offset: 1 }" :wrapper-col="{ span: 24, offset: 1.5 }"
                        name="articleContent">
                        <tinymce-editor id="edit" v-model:value="form.activityContent" :init="{ height: 400 }" />
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-modal>
</template>
  
<script>
import * as activityInfoApi from '@/api/activity/activityInfo.js'
import TinymceEditor from '@/components/TinymceEditor'
export default {
    name: 'activityRecordEdit',
    emits: ['done', 'update:visible'],
    props: {
         // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        activityId: Object
    },
    components: {
        TinymceEditor
    },
    data() {
        return {
            // 表单数据
            form: { activityContent: '' },
            // 编辑弹窗表单验证规则
            rules: {},
            // 提交状态
            loading: false,
            loading2: false,
        }
    },
    watch: {
        activityId() {   
            this.form = { activityContent: ''}         
            this.form.activityId = this.activityId
            this.getActivityContent(this.form.activityId)
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        }
    },
    mounted() {
    },
    methods: {
        // 获取富文本活动内容
        getActivityContent(id) {
            activityInfoApi
                .getRecordById(id)
                .then((res) => {
                    if (res.code === 0) {
                        if (res.data) {
                            this.form = res.data
                            this.form.articleUrl = ''
                        } else {
                            this.form = {}
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
                .catch((e) => {
                    this.$message.error(e.message)
                })
        },
        /* 保存编辑 */
        save() {
            this.$refs.form
                .validate()
                .then(() => {
                    this.loading = true
                    this.form.activityId = this.activityId
                    activityInfoApi
                        .saveRecord(this.form)
                        .then((res) => {
                            this.loading = false
                            if (res.code === 0) {
                                this.$message.success(res.msg)
                                this.resetForm()
                                this.updateVisible(false)                                
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                        .catch((e) => {
                            this.loading = false
                            this.$message.error(e.message)
                        })
                })
                .catch(() => { })
        },
         /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value)
        },
        getArticleHtml() {
            this.loading2 = true
            const data = {
                url: this.form.articleUrl
            }
            activityInfoApi
                .getArticleHtml(data)
                .then((res) => {
                    this.loading2 = false
                    if (res.code === 0) {
                        this.form.activityContent = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                })
                .catch((e) => {
                    this.loading2 = false
                    this.$message.error(e.message)
                })
        },
        resetForm() {
            this.form = {}
        }
    }
}
</script>
  
<style lang="less" scoped>
.form-info-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .info {
        margin-right: 5px;
        font-size: 12px;
        color: #d6d7d9;
    }
}

.flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d6d7d9;
    margin: 20px 0;
    padding: 10px 20px;

    .author-content {
        width: 800px;

        .author-item {
            display: flex;
            width: 100%;
            margin-bottom: 10px;

            .value {
                width: 80%;
                white-space: wrap;
                color: #9b9b9b;
            }
        }
    }

    .title {
        display: inline-block;
        width: 40px;
        margin-right: 5px;
    }
}
</style>
  