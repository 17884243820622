<!-- 编辑弹窗 -->
<template>
  <div>
    <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane :key="1" tab="签到设置" force-render>
          <a-row :gutter="16">
            <a-col :sm="12" :xs="24">
              <a-form-item label="签到开始时间:" name="signStartTime">
                <a-date-picker v-model:value="form.signStartTime" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                  class="ud-fluid">
                  <template #suffixIcon>
                    <calendar-outlined />
                  </template>
                </a-date-picker>
              </a-form-item>
              <a-form-item label="范围:" name="signScope">
                <a-input-number :min="1" placeholder="请输入范围" v-model:value="form.signScope" allow-clear
                  style="width: 100%">
                </a-input-number>
              </a-form-item>
            </a-col>
            <a-col :sm="12" :xs="24">
              <a-form-item label="签到结束时间:" name="signEndTime">
                <a-date-picker v-model:value="form.signEndTime" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                  class="ud-fluid">
                  <template #suffixIcon>
                    <calendar-outlined />
                  </template>
                </a-date-picker>
              </a-form-item>
              <!-- <a-form-item label="签到口令:" name="signPassword">
            <a-input placeholder="请输入签到口令" v-model:value="form.signPassword" allow-clear style="width: 100%">
            </a-input>
          </a-form-item> -->
            </a-col>
          </a-row>
          <a-row>
            <a-col :sm="24" :xs="24">
              <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
                <a-space>
                  <a-button type="primary" @click="save">保存</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-form>
  </div>
</template>

<script>
import * as activityInfoApi from '@/api/activity/activityInfo.js'
import { CalendarOutlined } from '@ant-design/icons-vue'

export default {
  name: 'signSetting',
  emits: ['done'],
  props: {
    activityId: Number,
    formId: Number
  },
  components: {
    CalendarOutlined
  },
  data() {
    return {
      activeKey: 1,
      // 表单数据
      form: {},
      // 编辑弹窗表单验证规则
      rules: {
        signStartTime: [
          {
            type: 'string',
            validateTrigger: 'change',
            validator: (rule, value) => {
              if (value && this.form.signEndTime) {
                const signStartTime = new Date(value).getTime()
                const signEndTime = new Date(this.form.signEndTime).getTime()
                if (signEndTime < signStartTime) {
                  return Promise.reject(
                    new Error('请确保签到开始时间在签到结束时间之前')
                  )
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        signEndTime: [
          {
            type: 'string',
            validateTrigger: 'change',
            validator: (rule, value) => {
              if (value && this.form.signStartTime) {
                const signStartTime = new Date(
                  this.form.signStartTime
                ).getTime()
                const signEndTime = new Date(value).getTime()
                if (signEndTime < signStartTime) {
                  return Promise.reject(
                    new Error('请确保签到结束时间在签到开始时间之后')
                  )
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ]
      },
      // 提交状态
      loading: false
    }
  },
  watch: {
    activityId() {
      if (this.activityId) {
        this.getActivityInfo()
      }
    }
  },
  mounted() {
    this.getActivityInfo()
  },
  methods: {
    // 查询活动信息
    getActivityInfo() {
      activityInfoApi
        .getById(this.activityId)
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.form = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          activityInfoApi
            .save(this.form, true)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    /* 重置 */
    reset() {
      this.getActivityInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6d7d9;
  margin: 20px 0;
  padding: 10px 20px;

  .author-content {
    width: 800px;

    .author-item {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      .value {
        width: 80%;
        white-space: wrap;
        color: #9b9b9b;
      }
    }
  }

  .title {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
  }
}
</style>
