<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="900"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
    @cancel="modalCancel"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 8 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 16 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <a-form-item label="活动名称:">
            <div class="ud-text-secondary">
              {{ data.activityName }}
            </div>
          </a-form-item>
          <!-- 租赁点名称 -->
          <a-form-item label="活动类型:">
            <div class="ud-text-secondary">
              {{ data.activityType }}
            </div>
          </a-form-item>
          <!-- 租赁点类型 -->
          <a-form-item label="地址:">
            <div class="ud-text-secondary">
              {{ data.address }}
            </div>
          </a-form-item>
          <a-form-item label="活动开始时间:">
            <div class="ud-text-secondary">
              {{ data.actStartTime }}
            </div>
          </a-form-item>
          <a-form-item label="活动结束时间:">
            <div class="ud-text-secondary">
              {{ data.actEndTime }}
            </div>
          </a-form-item>
          <a-form-item label="状态:">
            <div class="ud-text-secondary">
              {{ data.state }}
            </div>
          </a-form-item>
          <a-form-item label="活动费用:" v-if="data.costType !== 1">
            <div class="ud-text-secondary" v-if="data.cost">
              {{ data.cost }}元
            </div>
          </a-form-item>
          <a-form-item label="男:" v-else>
            <a-row style="width: 100%">
              <a-col :span="8">
                <div class="ud-text-secondary" v-if="data.cost">
                  {{ data.cost }}元
                </div>
              </a-col>
              <a-col :span="8">
                <a-form-item label="女">
                  <div class="ud-text-secondary" v-if="data.cost">
                    {{ data.cost }}元
                  </div></a-form-item
                >
              </a-col>
            </a-row>
          </a-form-item>

          <!-- 管理人 -->
          <a-form-item label="创建时间:">
            <div class="ud-text-secondary">
              {{ data.createTime }}
            </div>
          </a-form-item>
          <a-form-item label="商家图片:">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.corpPic" width="100" />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 租赁点类型 -->
          <a-form-item label="商家名称:">
            <div class="ud-text-secondary">
              {{ data.corpName }}
            </div>
          </a-form-item>
          <a-form-item label="商家活动次数:">
            <div class="ud-text-secondary">
              {{ data.corpActivityCount }}
            </div>
          </a-form-item>
          <!-- 管理人 -->
          <a-form-item label="商家活动参与人数:">
            <div class="ud-text-secondary">
              {{ data.corpActivityUserCount }}
            </div>
          </a-form-item>
          <a-form-item label="报名开始时间:">
            <div class="ud-text-secondary">
              {{ data.startTime }}
            </div>
          </a-form-item>
          <a-form-item label="报名结束时间:">
            <div class="ud-text-secondary">
              {{ data.endTime }}
            </div>
          </a-form-item>
          <a-form-item label="管理员:">
            <div class="ud-text-secondary">
              {{ data.manageId }}
            </div>
          </a-form-item>
          <a-form-item label="最大人数:">
            <a-row style="width: 100%">
              <a-col :span="8">
                <div class="ud-text-secondary" v-if="data.maxCount">
                  {{ data.maxCount }}人
                </div>
              </a-col>
              <a-col :span="8" v-if="data.maxCount" >
                <a-form-item label="男">
                  <div class="ud-text-secondary"  v-if="data.maleCount">
                    {{ data.maleCount }}人
                  </div></a-form-item
                >
              </a-col>
              <a-col :span="8" v-if="data.maxCount">
                <a-form-item label="女">
                  <div class="ud-text-secondary" v-if="data.femaleCount">
                    {{ data.femaleCount }}人
                  </div></a-form-item
                >
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="活动主图:">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.activityMainPic" width="100" />
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :sm="24">
          <a-form-item
            label="活动图片:"
            :labelCol="{ span: 4, offset: 0 }"
            :wrapper-col="{ span: 20, offset: 0 }"
          >
            <div
              style="display: flex; flex-wrap: wrap"
              v-if="data.activityPics"
            >
              <div
                class="ud-text-secondary"
                style="margin-right: 20px"
                v-for="(item, index) in data.activityPics.split(',')"
                :key="index"
              >
                <img class="photo" :src="item" width="100" />
              </div>
            </div>
            <div v-else></div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24">
          <a-form-item
            label="内容"
            :labelCol="{ span: 2, offset: 1 }"
            :wrapper-col="{ span: 22, offset: 0 }"
            name="articleContent"
          >
            <tinymce-editor
              v-model:value="activityContent"
              disabled
              id="detail"
              :init="{ height: 400 }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import * as activityInfoApi from '@/api/activity/activityInfo.js'
export default {
  name: 'OperRecordDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  components: {
    TinymceEditor
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      activityContent: null
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.getActivityContent(this.form.activityId)
      }
    }
  },
  methods: {
    // 获取富文本活动内容
    getActivityContent(id) {
      activityInfoApi
        .getContentById(id)
        .then((res) => {
          if (res.code === 0) {
            // console.log(res.data)
            this.activityContent = res.data.activityContent
            // this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.photo {
  width: 200px;
  height: 100px;
  margin: 20px 0;
}
.ud-text-secondary {
  line-height: 32px;
}
</style>
