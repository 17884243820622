<template>
  <a-modal :width="680" :visible="visible" :confirm-loading="loading" :title="'导入活动安排'"
    :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
    <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }">
      <a-row :gutter="16">
        <a-upload :file-list="fileList" :before-upload="beforeUpload">
          <div v-if="fileList.length < 1">
            <a-button>
              <upload-outlined></upload-outlined>
              文件选择
            </a-button>
          </div>
        </a-upload>
      </a-row>
      <a-row :gutter="16">
        <a target="_blank" href="/static/files/活动安排导入模板.xlsx">活动安排导入模板下载</a>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as activityUserSignApi from '@/api/activity/activityUserSign'
export default {
  name: 'sign-import',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    activityId: Number
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      fileList: []
    }
  },
  created() {
  },
  mounted() { },
  methods: {
    save() {
      if (this.fileList.length === 0) {
        this.$message.error('请选择文件')
        return
      }
      var formData = new FormData()
      formData.append('file', this.fileList[0])
      activityUserSignApi
        .imports(this.activityId, formData)
        .then((res) => {
          if (res.code === 0) {
            this.fileList = []
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          }
        })
        .catch((e) => { })
    },
    beforeUpload(file) {
      this.fileList = [file]
      return false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
      this.$emit('update:data', this.form)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
