<!-- 编辑弹窗 -->
<template>
  <div>
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
    >
      <a-tabs v-model:activeKey="activeKey">
        <!-- <a-tab-pane :key="1" tab="报名设置" force-render>
          <a-row :gutter="16">
            <a-col :sm="12" :xs="24">
              <a-form-item label="报名开始时间:" name="startTime">
                <a-date-picker
                  v-model:value="form.startTime"
                  :show-time="true"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  class="ud-fluid"
                >
                  <template #suffixIcon>
                    <calendar-outlined />
                  </template>
                </a-date-picker>
              </a-form-item>
              <a-form-item label="人数:" name="maxCount">
                <a-input-number
                  v-model:value="form.maxCount"
                  placeholder="请输入人数"
                  @change="changeRatio"
                  allow-clear
                  style="width: 100%"
                />
              </a-form-item>
              <a-form-item label="费用类型" name="costType">
                <a-radio-group v-model:value="form.costType">
                  <a-radio :value="1">统一费用</a-radio>
                  <a-radio :value="2">按性别男女</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="是否审核" name="regApply">
                <a-radio-group v-model:value="form.regApply">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="12" :xs="24">
              <a-form-item label="报名结束时间:" name="endTime">
                <a-date-picker
                  v-model:value="form.endTime"
                  :show-time="true"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  class="ud-fluid"
                >
                  <template #suffixIcon>
                    <calendar-outlined />
                  </template>
                </a-date-picker>
              </a-form-item>
              <a-form-item :wrapper-col="{ span: 24, offset: 3 }">
                <div style="display: flex; height: 30px">
                  <a-form-item name="maleCount" label="男生:">
                    <a-input-number
                      v-model:value="form.maleCount"
                      placeholder="请输入男生人数"
                      allow-clear
                      style="width: 120px"
                    />
                  </a-form-item>
                  <a-form-item name="femaleCount" label="女生">
                    <a-input-number
                      v-model:value="form.femaleCount"
                      placeholder="请输入女生人数"
                      allow-clear
                      style="width: 120px"
                    />
                  </a-form-item>
                  <div class="flex-box">
                    <a-space>
                      <a
                        href="javascript:;"
                        :style="{ color: form.maxCount ? '' : '#d6d7d9' }"
                        @click="quickSet(1)"
                        >1:1</a
                      >
                      <a
                        href="javascript:;"
                        :style="{ color: form.maxCount ? '' : '#d6d7d9' }"
                        @click="quickSet(2)"
                        >1:2</a
                      >
                    </a-space>
                  </div>
                </div>
              </a-form-item>
              <a-form-item
                name="cost"
                v-if="form.costType === 2"
                :wrapper-col="{ span: 24, offset: 3 }"
              >
                <div style="display: flex; height: 30px">
                  <a-form-item name="maleCost" label="男生">
                    <a-input-number
                      v-model:value="form.maleCost"
                      placeholder="请输入男生活动费用"
                      allow-clear
                      style="width: 120px"
                    />
                  </a-form-item>
                  <a-form-item name="femaleCost" label="女生">
                    <a-input-number
                      v-model:value="form.femaleCost"
                      placeholder="请输入女生活动费用"
                      allow-clear
                      style="width: 120px"
                    />
                  </a-form-item>
                  <div class="flex-box"></div>
                </div>
              </a-form-item>
              <a-form-item label="活动费用:" name="cost" v-else>
                <a-input-number
                  v-model:value="form.cost"
                  placeholder="请输入活动费用"
                  allow-clear
                  style="width: 100%"
                />
              </a-form-item>

              <a-form-item label="报名权限" name="regAuthor">
                <a-radio-group
                  v-model:value="form.regAuthor"
                  @change="regAuthorChange"
                >
                  <a-radio :value="1">公开</a-radio>
                  <a-radio :value="2">内部成员</a-radio>
                  <a-radio :value="3">指定人员</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :sm="12" :xs="24">
              <a-form-item
                v-if="form.regAuthor === 3"
                label="指定人员:"
                name="member"
              >
                <a-select
                  mode="multiple"
                  v-model:value="form.member"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="(value, index) in memberList"
                    :key="index"
                    :value="value.name"
                    >{{ value.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :sm="24" :xs="24">
              <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
                <a-space>
                  <a-button type="primary" @click="save">保存</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-tab-pane> -->
        <a-tab-pane :key="1" tab="报名信息">
          <a-row :gutter="16">
            <a-col :sm="24" :xs="24">
              <fromFieldList
                ref="actField"
                v-model:value="fieldList"
                :corpId="corpId"
                :formId="formId"
                @done="resetField"
              />
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-form>
    <a-modal
      :width="1000"
      title="选择指定成员"
      v-model:visible="showMemberList"
      :confirm-loading="loading"
      @ok="selectMember"
    >
      <memberList ref="memberList" :corpId="corpId" />
    </a-modal>
  </div>
</template>

<script>
import * as activityInfoApi from '@/api/activity/activityInfo.js'
import fromFieldList from '../form/field.vue'
import memberList from './member-list.vue'

export default {
  name: 'regSetting',
  emits: ['done'],
  props: {
    activityId: Number,
    formId: Number
  },
  components: {
    fromFieldList,
    memberList
  },
  data() {
    return {
      fieldList: [],
      showMemberList: false, // 是否显示成员列表
      corpId: 0, // 团队Id
      activeKey: 1,
      // 表单数据
      form: {
        member: []
      },
      // 编辑弹窗表单验证规则
      rules: {
        startTime: [
          {
            type: 'string',
            validateTrigger: 'change',
            validator: (rule, value) => {
              if (value && this.form.endTime) {
                const startTime = new Date(value).getTime()
                const endTime = new Date(this.form.endTime).getTime()
                if (endTime < startTime) {
                  return Promise.reject(
                    new Error('请确保报名开始时间在报名结束时间之前')
                  )
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        endTime: [
          {
            type: 'string',
            validateTrigger: 'change',
            validator: (rule, value) => {
              if (this.form.startTime && value) {
                const startTime = new Date(this.form.startTime).getTime()
                const endTime = new Date(value).getTime()
                if (endTime < startTime) {
                  return Promise.reject(
                    new Error('请确保报名结束时间在报名开始时间之后')
                  )
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        maleCount: [
          {
            type: 'number',
            validateTrigger: 'blur',
            validator: (rule, value) => {
              this.ratioType = 0
              // this.$refs.form.validateField('manCount')
              if (this.form.femaleCount + value > this.form.maxCount) {
                return Promise.reject(
                  new Error('请确保男女人数不能大于最大人数')
                )
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        femaleCount: [
          {
            type: 'number',
            validateTrigger: 'blur',
            validator: (rule, value) => {
              this.ratioType = 0
              if (this.form.maleCount + value > this.form.maxCount) {
                return Promise.reject(
                  new Error('请确保男女人数不能大于最大人数')
                )
              } else {
                return Promise.resolve()
              }
            }
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      ratioType: 0,
      memberList: []
    }
  },
  watch: {
    activityId() {
      if (this.activityId) {
        this.getActivityInfo()
      }
    }
  },
  mounted() {
    this.getActivityInfo()
  },
  methods: {
    getRemoveIds() {
      return this.$refs.actField.getRemoveIds()
    },
    resetFieldData() {
      this.$refs.actField.getTemField()
    },
    selectClear(e) {
      this.form.member = []
    },
    selectMember() {
      this.memberList = this.$refs.memberList.selection
      console.log(this.memberList)
      this.form.member = this.memberList.map((item) => {
        return item.name
      })
      this.showMemberList = false
      console.log('member', this.form.member)
    },
    regAuthorChange(e) {
      if (this.form.regAuthor === 3) {
        this.showMemberList = true
      } else {
        this.showMemberList = false
      }
    },
    // 查询活动信息
    getActivityInfo() {
      activityInfoApi
        .getById(this.activityId)
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.form = res.data
            this.corpId = res.data.corpId
            console.log('corpId', this.corpId)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    // 人数输入改变清空男女人数
    changeRatio() {
      if (this.form.maxCount) {
        this.quickSet(this.ratioType)
      } else {
        this.form.maleCount = null
        this.form.femaleCount = null
      }
    },
    // 快捷设置男女人数
    quickSet(type) {
      if (this.form.maxCount) {
        this.form.maleCount = null
        this.form.femaleCount = null
        if (type === 1) {
          this.ratioType = 1
          const remainder = this.form.maxCount % 2
          const count = Math.floor(this.form.maxCount / 2)
          if (remainder === 0) {
            this.form.maleCount = count
            this.form.femaleCount = count
          } else {
            this.form.maleCount = count + remainder
            this.form.femaleCount = count
          }
          return false
        }
        if (type === 2) {
          this.ratioType = 2
          const remainder = this.form.maxCount % 3
          const count = Math.floor(this.form.maxCount / 3)
          if (remainder === 0) {
            this.form.maleCount = count
            this.form.femaleCount = count * 2
          } else {
            this.form.maleCount = count + remainder
            this.form.femaleCount = count * 2
          }
          return false
        }
      } else {
        return false
      }
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      } else {
        return ''
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    resetForm() {
      this.form = {}
      this.activeKey = 1
    },
    filterForm() {
      let params = {}
      params = Object.assign({}, this.form)
      params.member = this.arrToStr(this.form.member)
      return params
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          // const params = this.filterForm()
          this.loading = true
          activityInfoApi
            .save(this.form, true)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 重置 */
    reset() {
      this.getActivityInfo()
    },
    resetField() {
      this.$emit('done', 'resetField')
    }
  }
}
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6d7d9;
  margin: 20px 0;
  padding: 10px 20px;

  .author-content {
    width: 800px;

    .author-item {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      .value {
        width: 80%;
        white-space: wrap;
        color: #9b9b9b;
      }
    }
  }

  .title {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
  }
}
</style>
