<template>
  <div class="">
    <a-modal
      :visible="value"
      title="字段类型"
      @update:visible="close"
      :footer="null"
    >
      <div class="field-type-wrap">
        <div class="field-type">
          <div
            @click="typeClick(item)"
            class="item"
            v-for="(item, index) in fieldTypeList"
            :key="index"
          >
            <span class="iconfont" :class="item.icon"></span>
            <span>{{ item.text }}</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  data() {
    return {
      fieldTypeList: [
        {
          icon: 'icon-danxingwenben',
          text: '单行文本'
        },
        {
          icon: 'icon-duoxingwenben',
          text: '多行文本'
        },
        {
          icon: 'icon-yuanshuju-shuzi',
          text: '数字'
        },
        {
          icon: 'icon-riqi',
          text: '日期'
        },
        {
          icon: 'icon-shijian',
          text: '时间'
        },
        {
          icon: 'icon-shoujihao',
          text: '手机号'
        },
        {
          icon: 'icon-danxuan',
          text: '单项选择'
        },
        {
          icon: 'icon-show_duoxuan',
          text: '多项选择'
        },
        {
          icon: 'icon-a-ziyuan30',
          text: '下拉框'
        },
        {
          icon: 'icon-diliweizhi',
          text: '地理位置'
        },
        {
          icon: 'icon-tupian',
          text: '单张图片'
        },
        {
          icon: 'icon-gengduotupian',
          text: '多张图片'
        },
        {
          icon: 'icon-B-shengshiqu',
          text: '省市区',
          desc: '适用于收货地址等'
        },
        {
          icon: 'icon-tuya',
          text: '涂写签名'
        },
        {
          icon: 'icon-biaoqian',
          text: '标签'
        },
        {
          icon: 'icon-icon_xiangguanmiaoshu',
          text: '描述'
        },
        {
          icon: 'icon-jiankangma',
          text: '健康码'
        },
        {
          icon: 'icon-shenfenzhenghaoma',
          text: '身份证号'
        },
        {
          icon: 'icon-tuandui1',
          text: '团队'
        }
      ]
    }
  },
  methods: {
    typeClick(item) {
      this.$emit('select', item)
    },
    close() {
      this.$emit('update:value', false)
    }
  }
}
</script>

<style lang="less" scoped>
.field-type-wrap {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  .iconfont {
    font-size: 20px;
    color: #00a6ef;
    margin-right: 10px;
  }
  .cancel {
    height: 80px;
    text-align: center;
    line-height: 80px;
  }
  .field-type {
    padding: 0 20px;
    margin: 20px;
    // box-sizing: border-box;
    border: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: '';
      display: inline-block;
      flex: 1;
    }
    .item {
      font-size: 20px;
      display: flex;
      align-items: center;
      width: 30%;
      cursor: pointer;
      // height: 40px;
      // background-color: red;
      box-sizing: border-box;
      padding: 20px 0;
      // margin-bottom: 20px;
      margin-right: calc(10% / 2);
    }

    .item:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
