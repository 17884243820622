<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal :width="950" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改' : '新建'"
      :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save" :zIndex="998">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }">
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动名称:" name="activityName">
              <a-input v-model:value="form.activityName" placeholder="请输入活动名称" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动类型:" name="activityType">
              <a-select v-model:value="form.activityType" placeholder="请选择活动类型" allow-clear>
                <a-select-option v-for="item in tempTypeList" :key="item.dictDataCode" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :sm="12" :xs="24">
            <a-form-item label="主办方:" name="corpId">
              <a-select
                :value="form.corpId"
                placeholder="请选择主办方"
                allow-clear
                @change="corpChange"
              >
                <a-select-option
                  v-for="item in activityStoreList"
                  :key="item.corpId"
                  :value="item.corpId"
                >
                  {{ item.corpName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>
        <a-row :gutter="16">
          <!-- <a-col :sm="12" :xs="24" v-if="!form.activityId">
            <a-form-item label="报名模板" name="formId">
              <a-select
                v-model:value="form.formId"
                placeholder="请选择报名模板"
                allow-clear
              >
                <a-select-option
                  v-for="item in activityFormList"
                  :key="item.fromId"
                  :value="item.formId"
                >
                  {{ item.formName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->

          <a-col :sm="12" :xs="24">
            <a-form-item label="团队首页展示" name="isPublic">
              <a-radio-group v-model:value="form.public">
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动时间开启" name="actModule">
              <a-radio-group v-model:value="form.actModule" default-value="true">
                <a-radio :value="true">开启</a-radio>
                <a-radio :value="false">关闭</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item label="管理员:" name="manangeId" :labelCol="{ span: 1.5, offset: 1 }"
              :wrapper-col="{ span: 24, offset: 1.5 }">
              <a-select v-model:value="form.manageIdArr" :token-separators="[',']" showArrow
                :filterOption="filterOption" mode="multiple" placeholder="请选择管理员">
                <a-select-option v-for="(item, index) in managerList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16" v-if="form.actModule">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动开始时间:" name="actStartTime">
              <a-date-picker v-model:value="form.actStartTime" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid">
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动结束时间:" name="actEndTime">
              <a-date-picker v-model:value="form.actEndTime" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid">
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动地址:" name="address">
              <a-input v-model:value="form.address" placeholder="请输入活动地址" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="地图坐标:" name="coordinate">
              <div>
                <a-button type="primary" @click="coordinate()">地图选点</a-button>
                <span style="margin-left: 10px">经度: {{ form.longitude }} 纬度: {{ form.latitude }}</span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动主图:" name="activityMainPic">
              <a-upload list-type="picture-card" v-model:file-list="activityMainPicList" @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, 'activityMainPic')
      " @remove="removeFile">
                <div v-if="activityMainPicList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动图片:" name="activityPics">
              <a-upload list-type="picture-card" v-model:file-list="activityPicsList" @preview="handlePreview" multiple
                :customRequest="({ file }) => uploadFile(file, 'activityPics')" @remove="removeFile">
                <div v-if="activityPicsList.length < 5">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="分享封面:" name="sharePic">
              <a-upload list-type="picture-card" v-model:file-list="sharePicList" @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, 'sharePic')" @remove="removeFile">
                <div v-if="sharePicList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24"> </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item label="内容" :labelCol="{ span: 1.5, offset: 1 }" :wrapper-col="{ span: 24, offset: 1.5 }"
              name="articleContent">
              <tinymce-editor id="edit" v-model:value="form.activityContent" :init="{ height: 400 }" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider style="height: 60px" />
        <!-- <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="报名时间开启" name="regModule">
              <a-radio-group v-model:value="form.regModule" default-value="true">
                <a-radio :value="true">开启</a-radio>
                <a-radio :value="false">关闭</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="报名开始时间:" name="startTime" v-if="form.regModule">
              <a-date-picker v-model:value="form.startTime" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid">
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker> </a-form-item></a-col>
          <a-col :sm="12" :xs="24"><a-form-item label="报名结束时间:" name="endTime" v-if="form.regModule">
              <a-date-picker v-model:value="form.endTime" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid">
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker> </a-form-item></a-col>
          <a-col :sm="12" :xs="24"><a-form-item label="报名人数" name="limitType">
              <a-radio-group v-model:value="form.limitType" @change="limitTypeChange">
                <a-radio :value="item.dictDataCode" v-for="(item, index) in limitTypeList" :key="index">{{
      item.dictDataName
    }}</a-radio>
              </a-radio-group>
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24"><a-form-item v-if="form.limitType === '最大人数'" label="人数:" name="maxCount">
              <a-input-number v-model:value="form.maxCount" placeholder="请输入人数" @change="changeRatio" allow-clear
                style="width: 100%" /> </a-form-item><a-form-item :wrapper-col="{ span: 24, offset: 3 }"
              v-if="form.limitType === '按男女'">
              <div style="display: flex; height: 30px">
                <a-form-item name="maleCount" label="男生:">
                  <a-input-number v-model:value="form.maleCount" placeholder="请输入男生人数" allow-clear
                    style="width: 120px" />
                </a-form-item>
                <a-form-item name="femaleCount" label="女生">
                  <a-input-number v-model:value="form.femaleCount" placeholder="请输入女生人数" allow-clear
                    style="width: 120px" />
                </a-form-item>
                <div class="flex-box">
                  <a-space>
                    <a href="javascript:;" :style="{ color: form.maxCount ? '' : '#d6d7d9' }"
                      @click="quickSet(1)">1:1</a>
                    <a href="javascript:;" :style="{ color: form.maxCount ? '' : '#d6d7d9' }"
                      @click="quickSet(2)">1:2</a>
                  </a-space>
                </div>
              </div>
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24"><a-form-item label="费用类型" name="costType">
              <a-radio-group v-model:value="form.costType" @change="costTypeChange">
                <a-radio :value="item.dictDataCode" v-for="(item, index) in costTypeList" :key="index">{{
      item.dictDataName
    }}</a-radio>
              </a-radio-group>
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24"><a-form-item name="cost" v-if="form.costType === '按男女'"
              :wrapper-col="{ span: 24, offset: 3 }">
              <div style="display: flex; height: 30px">
                <a-form-item name="maleCost" label="男生">
                  <a-input-number v-model:value="form.maleCost" placeholder="请输入男生活动费用" allow-clear
                    style="width: 120px" />
                </a-form-item>
                <a-form-item name="femaleCost" label="女生">
                  <a-input-number v-model:value="form.femaleCost" placeholder="请输入女生活动费用" allow-clear
                    style="width: 120px" />
                </a-form-item>
                <div class="flex-box"></div>
              </div>
            </a-form-item>
            <a-form-item label="活动费用:" name="cost" v-if="form.costType === '统一费用'">
              <a-input-number v-model:value="form.cost" placeholder="请输入活动费用" allow-clear style="width: 100%" />
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="是否审核" name="regApply">
              <a-radio-group v-model:value="form.regApply">
                <a-radio :value="item.dictDataCode" v-for="(item, index) in regApplyList" :key="index">{{
      item.dictDataName
    }}</a-radio>
              </a-radio-group>
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24"></a-col>
          <a-col :sm="12" :xs="24"><a-form-item label="报名权限" name="regAuthor">
              <a-radio-group v-model:value="form.regAuthor" @change="regAuthorChange">
                <a-radio :value="item.dictDataCode" v-for="(item, index) in regAuthorList" :key="index">{{
      item.dictDataName
    }}</a-radio>
              </a-radio-group>
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24"><a-form-item v-if="form.regAuthor === '指定成员'" label="指定成员:" name="member">
              <a-select mode="multiple" v-model:value="selectMember" placeholder="请选择" ref="selectMember" :open="isOpen"
                @change="memberChange" @dropdownVisibleChange="dropdownVisibleChange">
                <a-select-option v-for="(value, index) in memberList" :key="index" :value="value.value">{{ value.label
                  }}</a-select-option>
              </a-select>
            </a-form-item></a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item name="participantInfo" label="参与人填写项:">
              <div>
                <a-button type="primary" @click="showFieldSetClick">设置</a-button>
              </div>
              <div class="form-info-list" @click="showFieldSetClick">
                <div class="info" v-for="(item, index) in participantInfoList" :key="index">
                  <span style="color: red; margin-right: 4px" v-if="item.verify">*</span><span>{{ item.label }}</span>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
       <a-row>
          <a-form-item label="附件:" :labelCol="{ span: 2, offset: 1 }" :wrapper-col="{ span: 22, offset: 0 }"
            name="activityFile">
            <u-upload v-model:fileList="activityFileList" @afterRead="afterRead" @delete="deletePic" name="1" multiple :maxCount="10">
            </u-upload>
          </a-form-item>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item name="activityNotice" label="须知:" :labelCol="{ span: 1.5, offset: 1 }"
              :wrapper-col="{ span: 24, offset: 1.5 }">
              <tinymce-editor id="edit" v-model:value="form.activityNotice" :init="{ height: 400 }" />
            </a-form-item>
          </a-col>
        </a-row> -->
      </a-form>
    </a-modal>
    <a-modal :width="1100" :visible="showFieldSet" title="设置参与人填写项" :body-style="{ paddingBottom: '8px' }"
      @cancel="showFieldSet = false" @ok="fieldSetConfirm">
      <fromFieldList ref="activityField" :formId="form.formId" :corpId="form.corpId || 0" :action="1"
        v-model:value="fieldList"></fromFieldList>
    </a-modal>
    <a-modal :width="950" :visible="showPostion" title="地图选址" :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition" @ok="setPosition">
      <Map ref="map" v-model:position="position" id="pointAddress" />
    </a-modal>
    <member-select forceRender="true" :corpId="form.corpId || 0" ref="memberSelect" :member-selection="memberSelection"
      v-model:visible="showMemberSelect" @done="selectConfirm"></member-select>
  </div>
</template>

<script>
import * as activityInfoApi from '@/api/activity/activityInfo.js'
// import * as activityFormFieldApi from '@/api/activity/activityFormField.js'
import * as corpAPi from '@/api/corp/corp.js'
import * as activityUserRegApi from '@/api/activity/activityUserReg.js'
import * as activityFormApi from '@/api/activity/activityForm.js'
import * as dictDataApi from '@/api/dict/dictData.js'
import fromFieldList from './form/field.vue'
import setting from '@/config/setting'
import TinymceEditor from '@/components/TinymceEditor'
import memberSelect from './member-select/index.vue'
import Map from '@/components/map/addressPicker.vue'
import { toDateString } from '@/utils/util.js'
// import dayjs from 'dayjs'
import {
  CalendarOutlined,
  PlusOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'activityInfoEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    Map,
    CalendarOutlined,
    TinymceEditor,
    PlusOutlined,
    fromFieldList,
    memberSelect
  },
  data() {
    return {
      limitTypeList: [],
      regApplyList: [],
      costTypeList: [],
      regAuthorList: [],
      isOpen: false, // 是否展开下拉菜单
      memberSelection: [],
      selectMember: [],
      showMemberSelect: false,
      showFieldSet: false,
      activeKey: 1,
      showAuthor: false,
      authorInfo: null,
      authorInfoList: [
        {
          manageId: '',
          manageName: ''
        }
      ],
      participantInfoList: [],
      managerList: [],
      // 表单数据
      form: Object.assign({}, this.data),
      uploadUrl: setting.uploadUrl,
      // 编辑弹窗表单验证规则
      rules: {
        activityName: [
          {
            required: true,
            message: '请输入活动名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ],
        actStartTime: [
          {
            type: 'string',
            validateTrigger: 'change',
            validator: (rule, value) => {
              if (value && this.form.actEndTime) {
                const actStartTime = new Date(value).getTime()
                const actEndTime = new Date(this.form.actEndTime).getTime()
                if (actEndTime < actStartTime) {
                  return Promise.reject(
                    new Error('请确保活动开始时间在活动结束时间之前')
                  )
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        actEndTime: [
          {
            type: 'string',
            validateTrigger: 'change',
            validator: (rule, value) => {
              if (value && this.form.actStartTime) {
                const actStartTime = new Date(this.form.actStartTime).getTime()
                const actEndTime = new Date(value).getTime()
                if (actEndTime < actStartTime) {
                  return Promise.reject(
                    new Error('请确保活动结束时间在活动开始时间之后')
                  )
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        corpId: [
          {
            required: true,
            message: '请选择团队',
            type: 'number',
            validateTrigger: 'blur'
          }
        ],
        formId: [
          {
            required: true,
            message: '请选择报名模块',
            type: 'number',
            validateTrigger: 'blur'
          }
        ],
        maleCount: [
          {
            type: 'number',
            validateTrigger: 'blur',
            validator: (rule, value) => {
              this.ratioType = 0
              // this.$refs.form.validateField('manCount')
              if (this.form.femaleCount + value > this.form.maxCount) {
                return Promise.reject(
                  new Error('请确保男女人数不能大于最大人数')
                )
              } else {
                return Promise.resolve()
              }
            }
          }
        ],
        femaleCount: [
          {
            type: 'number',
            validateTrigger: 'blur',
            validator: (rule, value) => {
              this.ratioType = 0
              if (this.form.maleCount + value > this.form.maxCount) {
                return Promise.reject(
                  new Error('请确保男女人数不能大于最大人数')
                )
              } else {
                return Promise.resolve()
              }
            }
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      activityStateList: [],
      activityTypeList: [],
      activityPicsList: [],
      activityStoreList: [],
      activityMainPicList: [],
      activityFormList: [],
      sharePicList: [],
      showQuick: false,
      previewVisible: false,
      previewImage: '',
      // // 日期范围选择
      // daterange: [],
      // daterange2: [],
      showPostion: false,
      position: {},
      ratioType: 0,
      activityFileList: [],
      fieldList: [],
      removeIds: [],
      // 模板类型数组
      tempTypeList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.resetForm()
        this.form = this.data
        this.form.scope = 50
        this.form.manageIdArr = this.strToArr(this.form.manageId)
        this.getActivityContent(this.form.activityId)
        this.getActivityNotice(this.form.activityId)
        this.queryManagerList(this.form.activityId)
        this.getTemField()
      } else {
        this.form.scope = 50
        this.isUpdate = false
        this.resetForm()
        this.form.corpId = this.getCorpId()
      }
      if (this.form.activityMainPic) {
        this.activityMainPicList[0] = {
          uid: '-10',
          name: 'image.png',
          status: 'done',
          url: this.form.activityMainPic
        }
      }
      if (this.form.activityPics) {
        const picsArr = this.strToArr(this.form.activityPics)
        picsArr.forEach((item, index) => {
          this.activityPicsList.push({
            uid: `-${index + 1}`,
            name: 'image.png',
            status: 'done',
            url: item
          })
        })
      }
      if (this.form.sharePic) {
        this.sharePicList[0] = {
          uid: '-9',
          name: 'image.png',
          status: 'done',
          url: this.form.sharePic
        }
      }
      if (this.form.longitude) {
        this.position = {
          fullAddress: this.form.fullCity + this.form.address,
          longitude: this.form.longitude,
          latitude: this.form.latitude
        }
      } else {
        this.position = {}
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.resetForm()
    this.activityStore()
    this.getActivityForm()
    this.queryTempTypeStates()
    this.queryLimitTypeList()
    this.queryRegApplyList()
    this.queryCostTypeList()
    this.queryRegAuthorList()
  },
  methods: {
    queryLimitTypeList() {
      dictDataApi
        .getDictData({ dictCode: 'regNumber' })
        .then((res) => {
          if (res.code === 0) {
            this.limitTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryRegApplyList() {
      dictDataApi
        .getDictData({ dictCode: 'regApprove' })
        .then((res) => {
          if (res.code === 0) {
            this.regApplyList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryCostTypeList() {
      dictDataApi
        .getDictData({ dictCode: 'activityCost' })
        .then((res) => {
          if (res.code === 0) {
            this.costTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryRegAuthorList() {
      dictDataApi
        .getDictData({ dictCode: 'regLimit' })
        .then((res) => {
          if (res.code === 0) {
            this.regAuthorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    dropdownVisibleChange(e) {
      if (e) {
        this.isOpen = false
        this.showMemberSelect = true
      } else {
        this.isOpen = true
      }
    },
    // 指定人员选择改变
    memberChange(e) {
      this.memberSelection = this.memberSelection.filter((item) => {
        if (this.selectMember.includes(item.id)) {
          return item
        }
      })
    },
    // 成员选择完成
    selectConfirm(member) {
      this.memberSelection = member
      this.memberList = member.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      this.selectMember = member.map((item) => {
        return item.id
      })
      this.showMemberSelect = false
    },
    // 团队选择改变
    corpChange(e) {
      const that = this
      if (this.form.corpId && this.form.corpId !== e) {
        const referrerFieldIdArr = that.participantInfoList.filter((item) => {
          if (item.referrerFieldId) {
            return item
          }
        })
        if (referrerFieldIdArr.length > 0) {
          Modal.confirm({
            title: '切换团队,字段引用将失效！',
            icon: createVNode(ExclamationCircleOutlined),
            content: createVNode(
              'div',
              { style: 'color:red;' },
              '是否继续切换?'
            ),
            onOk() {
              that.form.corpId = e
              that.participantInfoList = that.participantInfoList.map(
                (item) => {
                  if (item.referrerFieldId) {
                    item.referrerFieldId = ''
                  }
                  return item
                }
              )
            },
            onCancel() { }
          })
        }
      } else {
        this.form.corpId = e
      }
      this.memberList = []
      this.selectMember = []
    },
    getTemField() {
      activityInfoApi.getById(this.form.activityId).then((res) => {
        if (res.code === 0) {
          this.fieldList = res.data.activityFormDataFields
          this.participantInfoList = res.data.formData.map((item) => {
            item.verify = item.rules.verify
            return item
          })
          this.initSelectMember(res.data.activityUserIdList)
        }
      })
    },
    reload(data) {
      this.authorInfoList.push(data)
    },
    limitTypeChange(e) {
      console.log('报名人数', e)
    },
    costTypeChange(e) {
      console.log('费用类型', e)
    },
    regAuthorChange(e) {
      const value = e.target.value
      if (value === '指定成员') {
        this.showMemberSelect = true
      } else {
        this.showMemberSelect = false
      }
    },
    showFieldSetClick() {
      this.showFieldSet = true
    },
    // 参与人填写项确认
    fieldSetConfirm() {
      this.fieldList = this.$refs.activityField.fieldList
      this.removeIds = this.$refs.activityField.removeIds
      this.participantInfoList = this.fieldList.filter((item) => {
        return item.enable === true
      })
      this.form.activityFormDataFieldDTOS = {
        fieldList: this.fieldList,
        removeIds: this.removeIds
      }
      this.showFieldSet = false
    },
    // 打开新建管理权限弹窗
    openAuthorInfo(row) {
      this.authorInfo = row
      this.showAuthor = true
    },
    // 人数输入改变清空男女人数
    changeRatio() {
      if (this.form.maxCount) {
        this.quickSet(this.ratioType)
      } else {
        this.form.maleCount = null
        this.form.femaleCount = null
      }
    },
    // 快捷设置男女人数
    quickSet(type) {
      if (this.form.maxCount) {
        this.form.maleCount = null
        this.form.femaleCount = null
        if (type === 1) {
          this.ratioType = 1
          const remainder = this.form.maxCount % 2
          const count = Math.floor(this.form.maxCount / 2)
          if (remainder === 0) {
            this.form.maleCount = count
            this.form.femaleCount = count
          } else {
            this.form.maleCount = count + remainder
            this.form.femaleCount = count
          }
          return false
        }
        if (type === 2) {
          this.ratioType = 2
          const remainder = this.form.maxCount % 3
          const count = Math.floor(this.form.maxCount / 3)
          if (remainder === 0) {
            this.form.maleCount = count
            this.form.femaleCount = count * 2
          } else {
            this.form.maleCount = count + remainder
            this.form.femaleCount = count * 2
          }
          return false
        }
      } else {
        return false
      }
    },
    coordinate() {
      this.postion = this.form
      this.showPostion = true
    },
    setPosition() {
      const data = this.$refs.map.form
      this.form.longitude = data.longitude
      this.form.latitude = data.latitude
      this.form.address = data.fullAddress
      this.showPostion = false
    },
    cancelPosition() {
      this.showPostion = false
    },
    removeFile(file) {
      const uid = file.uid
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (uid === '-10') {
              this.activityMainPicList = []
              this.form.activityMainPic = ''
            } else if (uid === '-9') {
              this.sharePicList = []
              this.form.sharePic = ''
            } else {
              if (this.activityPicsList.length > 0) {
                const picsArr = this.activityPicsList.map((item) => {
                  return item.url
                })
                this.form.activityPics = this.arrToStr(picsArr)
              } else {
                this.form.activityPics = ''
              }
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      console.log('file', file)
      console.log('fileList', this.activityPicsList)
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'activityMainPic') {
              this.activityMainPicList = [
                {
                  uid: uid,
                  name: 'image.png',
                  status: 'done',
                  url: res.data.location
                }
              ]
              this.form[name] = res.data.location
            } else if (name === 'activityPics') {
              this.activityPicsList[this.activityPicsList.length - 1] = {
                uid: uid,
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              if (this.activityPicsList.length > 0) {
                const picsArr = this.activityPicsList.map((item) => {
                  return item.url
                })
                this.form[name] = this.arrToStr(picsArr)
              }
            } else if (name === 'sharePic') {
              this.sharePicList[this.sharePicList.length - 1] = {
                uid: uid,
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              if (this.sharePicList.length > 0) {
                const picsArr = this.sharePicList.map((item) => {
                  return item.url
                })
                this.form[name] = this.arrToStr(picsArr)
              }
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    // 获取富文本活动内容
    getActivityContent(id) {
      if (this.isUpdate) {
        activityInfoApi
          .getContentById(id)
          .then((res) => {
            if (res.code === 0) {
              if (res.data) {
                this.form.activityContent = res.data.activityContent || ''
              } else {
                this.form.activityContent = ''
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    /* 查询活动模块 */
    getActivityForm() {
      activityFormApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.activityFormList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取富文本活动须知
    getActivityNotice(id) {
      if (this.isUpdate) {
        activityInfoApi
          .getNoticeById(id)
          .then((res) => {
            if (res.code === 0) {
              if (res.data) {
                this.form.activityNotice = res.data.activityNotice || ''
              } else {
                this.form.activityNotice = ''
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    resetForm() {
      this.form = {}
      this.activeKey = 1
      this.form.activityContent = ''
      this.form.activityNotice = ''
      this.activityPicsList = []
      this.activityMainPicList = []
      this.sharePicList = []
      this.form.scope = 50
      this.form.formId = 0
      this.form.corpId = null
      this.form.actModule = true
      this.form.regModule = true
      this.form.limitType = '无上限'
      this.form.regAuthor = '任何人'
      this.form.regApply = '不审核'
      this.form.costType = '不收费'
      this.form.public = true
      this.form.startTime = toDateString(new Date())
      this.participantInfoList = []
      if (!this.isUpdate) {
        const data = [
          {
            fieldId: 'name',
            referrerFieldId: null,
            dataType: null,
            placeholder: '请输入姓名',
            label: '姓名',
            type: '单行文本',
            dataScope: null,
            dataSource: null,
            oneKeyPhone: null,
            title: null,
            listData: null,
            verify: true,
            regexpData: null,
            errMsg: '姓名未填写',
            sortNumber: 1,
            minCount: null,
            maxCount: null,
            minDate: null,
            maxDate: null,
            dateDisplay: '年月日',
            defaultValue: null,
            uploadType: '上传设置',
            photoDesc: null,
            sampleGraph: null,
            provice: null,
            miniProgram: null,
            enable: true,
            distinct: false,
            readonly: null,
            hide: null,
            custom: false,
            activityContent: '',
            activityNotice: ''
          }
        ]
        this.fieldList = data
        this.participantInfoList = data
        this.memberSelection = []
        this.selectMember = []
        this.memberList = []
        this.$nextTick(() => {
          this.$refs.memberSelect.initSelectData()
          this.$refs.memberSelect.reload()
        })
      }
    },
    initSelectMember(data) {
      this.memberList = data.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      this.selectMember = data.map((item) => {
        return item.id
      })
      this.memberSelection = data
    },
    /* 保存编辑 */
    save() {
      if (!this.isUpdate) {
        this.form.corpId = this.getCorpId()
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.manageIdArr && this.form.manageIdArr.length > 0) {
            this.form.manageId = this.arrToStr(this.form.manageIdArr)
          } else {
            this.form.manageId = ''
          }
          if (!this.isUpdate) {
            this.form.formId = -1
          }
          this.form.activityFormDataFieldDTOS = {
            fieldList: this.fieldList,
            removeIds: this.removeIds
          }
          if (this.form.regAuthor === '指定成员') {
            this.form.activityUserIdList = this.memberSelection.map((item) => {
              return {
                id: item.id,
                name: item.name
              }
            })
          } else {
            this.form.activityUserIdList = []
          }
          activityInfoApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                // this.resetForm()
                if (!this.isUpdate) {
                  this.resetForm()
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // /* 查询活动商家 */
    activityStore() {
      corpAPi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.activityStoreList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryManagerList(activityId) {
      activityUserRegApi
        .all({ activityId: activityId })
        .then((res) => {
          if (res.code === 0) {
            this.managerList = res.data.map((item) => {
              const newItem = {}
              newItem.value = item.userId + ''
              newItem.label = '(' + item.phone + ')' + item.name
              return newItem
            })
          }
        })
        .catch(() => { })
    },
    filterOption(input, option) {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // /* 查询模板类型 */
    queryTempTypeStates() {
      dictDataApi
        .getDictData({ dictCode: 'tempType' })
        .then((res) => {
          if (res.code === 0) {
            this.tempTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.form-info-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .info {
    margin-right: 5px;
    font-size: 12px;
    color: #d6d7d9;
  }
}

.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6d7d9;
  margin: 20px 0;
  padding: 10px 20px;

  .author-content {
    width: 800px;

    .author-item {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      .value {
        width: 80%;
        white-space: wrap;
        color: #9b9b9b;
      }
    }
  }

  .title {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
  }
}
</style>
