<template>
  <a-card :bordered="false">
    <a-form :model="where">
      <a-row>
        <a-space>
          <a-form-item label="字段名称:">
            <a-input
              v-model:value.trim="where.label"
              placeholder="请输入字段名称"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="字段选项:">
            <a-select
              v-model:value="where.fieldType"
              placeholder="请选择字段"
              allow-clear
              :dropdownMatchSelectWidth="true"
            >
              <a-select-option :value="null"> 请选择 </a-select-option>
              <a-select-option :value="1"> 常规字段 </a-select-option>
              <a-select-option :value="2"> 自定义字段 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="是否启用:">
            <a-switch
              :checked="where.enable"
              @change="(checked) => (where.enable = checked)"
            />
          </a-form-item>
          <a-form-item label="是否必填:">
            <a-switch
              :checked="where.verify"
              @change="(checked) => (where.verify = checked)"
            />
          </a-form-item>
          <a-form-item class="ud-span-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-space>
      </a-row>
    </a-form>
    <div>
      <div>
        <a-row :gutter="6">
          <a-col :span="12">
            <div class="left-wrap-flex">
              <div
                v-for="(item, index) in fieldList"
                :key="index"
                style="margin: 0 10px 10px 0"
              >
                <a-dropdown
                  placement="bottom"
                  @visibleChange="visibleChange(index)"
                >
                  <div
                    class="field-item"
                    :class="item.enable ? 'field-item-act' : 'field-item-def'"
                  >
                    <span
                      ><span
                        v-if="item.verify"
                        style="color: red; margin-right: 2px"
                        >*</span
                      >{{ item.label }}</span
                    >
                    <div class="xsj" v-if="item.enable"></div>
                    <div class="icon" v-if="item.enable">
                      <check-outlined
                        style="
                          color: #fff;
                          font-weight: bolder;
                          font-size: 16px;
                        "
                      />
                    </div>
                  </div>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        v-for="action in actionList(item)"
                        :key="action.name"
                      >
                        <span>{{ action.name }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
            <div class="add-field-wrap">
              <a-space>
                <span class="tips">没找到合适的?</span>
                <a-button @click="showFieldType = true" type="primary"
                  >添加字段</a-button
                >
                <a-button @click="showFieldTem = true" type="primary"
                  >模板导入</a-button
                >
                <a-button @click="showFormPreview = true" type="primary"
                  >表单预览</a-button
                >
              </a-space>
            </div>
          </a-col>
          <a-col :span="12" v-if="selection.length > 0">
            <div class="selection-list">
              <div
                class="list-item"
                v-for="(item, index) in selection"
                :key="index"
              >
                <div class="item-left">
                  <div class="sortNumber">
                    {{ index + 1 }}
                  </div>
                  <div class="field-type">
                    {{ item.type }}
                  </div>
                </div>
                <div class="item-right">
                  <div class="flex-box">
                    <div class="field-name">
                      {{ item.label }}
                    </div>
                    <div class="required">
                      <a-switch
                        v-model:checked="item.verify"
                        @change="fieldRequired($event, item)"
                      ></a-switch>
                      <span style="margin-left: 10px">必填</span>
                    </div>
                  </div>
                  <div class="flex-box">
                    <div>
                      <span class="edit-btn" @click="openEdit(item, 'copy')"
                        >复制</span
                      >
                      <span
                        @click="sortField(item, 'up', index)"
                        class="edit-btn"
                        v-if="index !== 0"
                        >上移</span
                      >
                      <span
                        @click="sortField(item, 'down', index)"
                        class="edit-btn"
                        v-if="index !== selection.length - 1"
                        >下移</span
                      >
                      <span
                        v-if="item.custom"
                        class="edit-btn"
                        @click="remove(item)"
                        >删除</span
                      >
                    </div>
                    <div>
                      <span class="edit-btn" @click="openEdit(item, 'edit')"
                        >编辑</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-card>
  <field-edit
    ref="fieldEdit"
    :fieldList="fieldList"
    v-model:visible="showEdit"
    :data="current"
    :corpId="corpId || 0"
    @done="saveTemField"
    :formId="formId"
  ></field-edit>
  <tem-export
    v-model:visible="showFieldTem"
    @done="selectTem"
    :formId="formId"
  ></tem-export>
  <type-select v-model:value="showFieldType" @select="selectType"></type-select>
  <form-preview
    :fieldList="fieldList"
    v-model:visible="showFormPreview"
    :formId="formId"
    :corpId="corpId || 0"
  ></form-preview>
</template>

<script>
import * as activityFormFieldApi from '@/api/activity/activityFormField.js'
// import * as activityFormApi from '@/api/activity/activityForm.js'
import temExport from './temExport.vue'
import fieldEdit from './field-edit'
import typeSelect from './type-select.vue'
import formPreview from './formPreview.vue'
import { createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons-vue'
export default {
  emits: ['done', 'update:value'],
  components: {
    fieldEdit,
    CheckOutlined,
    typeSelect,
    temExport,
    formPreview
  },
  props: {
    // 弹窗是否打开
    formId: Number,
    corpId: Number,
    action: Number,
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    selection() {
      return this.fieldList.filter((item) => {
        return item.enable === true
      })
    }
  },
  watch: {
    formId() {
      if (
        this.formId &&
        this.formId !== 'undefined' &&
        this.fieldList.action !== 1
      ) {
        this.getTemField()
      } else {
        this.fieldList = this.value
      }
    },
    fieldList: {
      handler() {
        this.inputValue()
      },
      deep: true
    }
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tableReg.tableIndex + index
        },
        {
          title: '名称',
          dataIndex: 'label'
        },
        {
          title: '类型',
          dataIndex: 'type'
        },
        {
          title: '描述',
          dataIndex: 'placeholder'
        },
        {
          title: '错误提示',
          dataIndex: 'errMsg'
        },
        {
          title: '启用',
          dataIndex: 'enable',
          sorter: true,
          width: 90,
          align: 'center',
          slots: { customRender: 'enable' }
        },
        {
          title: '必填',
          dataIndex: 'verify',
          sorter: true,
          width: 90,
          align: 'center',
          slots: { customRender: 'verify' }
        },
        {
          title: '排序',
          dataIndex: 'sortNumber',
          width: 80,
          sorter: true
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: { order: 'asc', sort: 'sortNumber' },
      fieldList: [],
      activeIndex: 0,
      showEdit: false,
      showFieldType: false,
      current: null,
      custom: true,
      showFieldTem: false,
      showFormPreview: false,
      formData: [],
      removeIds: []
    }
  },
  mounted() {
    console.log('fieldCorpId', this.corpId)
    console.log('formId-corp', this.formId)
    // if (this.formId && this.formId !== 'undefined') {
    //   this.getTemField()
    // }
    if (this.action !== 1) {
      console.log('action', this.action)
      this.getTemField()
    } else {
      // console.log('value', this.value)
      this.fieldList = this.value
    }
  },
  methods: {
    getRemoveIds() {
      return this.removeIds
    },
    setFieldList(data) {
      this.fieldList = data
    },
    // 选择模板导入
    selectTem(data) {
      data.forEach((item) => {
        delete item.id
        delete item.formId
        delete item.fieldId
        const index = this.fieldList.findIndex((val) => {
          return val.label === item.label
        })
        // 如果导出字段没有则新增
        if (index === -1) {
          this.saveTemField(item, false, 'export')
        } else {
          // 如果导出字段存在则更新
          this.saveTemField(item, true, 'export')
        }
      })
    },
    selectType(data) {
      this.showFieldType = false
      this.showEdit = true
      this.$refs.fieldEdit.addField(data.text, 'add')
    },
    saveTemField(formData, isUpdate, type) {
      if (isUpdate) {
        let id = null
        let formId = null
        const index = this.fieldList.findIndex((item) => {
          if (item.id) {
            id = item.id
            formId = item.formId
          }
          return item.label === formData.label
        })
        if (type === 'export') {
          if (id && index > -1) {
            this.fieldList.splice(
              index,
              1,
              Object.assign(formData, { id, formId })
            )
          } else {
            this.fieldList.splice(index, 1, formData)
          }
        } else {
          this.fieldList.splice(this.activeIndex, 1, formData)
        }
      } else {
        this.fieldList.push(formData)
      }
      if (type === 'export') {
        this.showFieldTem = false
      } else {
        this.showEdit = false
      }
    },
    remove(obj) {
      const that = this
      Modal.confirm({
        // title: 'Do you want to delete these items?',
        icon: createVNode(ExclamationCircleOutlined),
        content: `是否删除${obj.label}字段吗?`,
        onOk() {
          const index = that.fieldList.findIndex((item) => {
            return item.label === obj.label
          })
          if (obj.id) {
            that.removeIds.push(obj.id)
          }
          that.fieldList.splice(index, 1)
        },
        onCancel() {}
      })
    },
    // 字段排序
    sortField(obj, type, index) {
      if (type === 'down') {
        // 移动索引
        const moveIndex = this.fieldList.findIndex((item) => {
          return item.label === obj.label
        })
        // 交换索引
        const downIndex = this.fieldList.findIndex((item) => {
          return item.label === this.selection[index + 1].label
        })
        this.fieldList[moveIndex] = this.fieldList.splice(
          downIndex,
          1,
          this.fieldList[moveIndex]
        )[0]
      }
      if (type === 'up') {
        const moveIndex = this.fieldList.findIndex((item) => {
          return item.label === obj.label
        })
        // 交换索引
        const downIndex = this.fieldList.findIndex((item) => {
          return item.label === this.selection[index - 1].label
        })
        this.fieldList[moveIndex] = this.fieldList.splice(
          downIndex,
          1,
          this.fieldList[moveIndex]
        )[0]
      }
      this.inputValue()
    },
    fieldRequired(e, obj) {
      const index = this.fieldList.findIndex((item) => {
        return item.label === obj.label
      })
      this.fieldList[index].verify = e
      this.inputValue()
    },
    visibleChange(index) {
      this.activeIndex = index
    },
    // 处理菜单点击事件
    handleMenuClick(e) {
      const obj = this.fieldList[this.activeIndex]
      console.log('obj', obj, 'e', e)
      if (e.key === '不作为填写项') {
        this.fieldList[this.activeIndex].enable = false
        this.fieldList[this.activeIndex].verify = false
      }
      if (e.key === '作为选填项') {
        this.fieldList[this.activeIndex].enable = true
        this.fieldList[this.activeIndex].verify = false
      }
      if (e.key === '作为必填项') {
        this.fieldList[this.activeIndex].enable = true
        this.fieldList[this.activeIndex].verify = true
      }
      if (e.key === '编辑该字段') {
        this.openEdit(obj, 'edit')
      }
      if (e.key === '复制该字段') {
        this.openEdit(obj, 'copy')
      }
    },
    // 操作表单字段
    actionList(item) {
      if (!item.enable) {
        return [
          {
            name: '作为必填项'
          },
          {
            name: '作为选填项'
          },
          {
            name: '编辑该字段'
          },
          {
            name: '复制该字段'
          }
        ]
      } else if (item.enable && item.verify) {
        return [
          {
            name: '不作为填写项'
          },
          {
            name: '作为选填项'
          },
          {
            name: '编辑该字段'
          },
          {
            name: '复制该字段'
          }
        ]
      } else {
        return [
          {
            name: '不作为填写项'
          },
          {
            name: '作为必填项'
          },
          {
            name: '编辑该字段'
          },
          {
            name: '复制该字段'
          }
        ]
      }
    },
    getTemField() {
      activityFormFieldApi
        .page({
          ...this.where,
          ...this.order,
          formId: this.formId,
          page: 1,
          limit: 1000
        })
        .then((res) => {
          if (res.code === 0) {
            this.fieldList = res.data
            console.log('hhhhh2', res.data)
            // .map((item) => {
            //   return {
            //     label: item.label,
            //     id: item.id,
            //     type: item.type,
            //     enable: item.enable,
            //     verify: item.verify,
            //     sortNumber: item.sortNumber,
            //     custom: item.custom
            //   }
            // })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    inputValue() {
      this.$emit('update:value', this.fieldList)
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = { ...this.order }
      }
      // this.reload()
    },
    /* 打开编辑弹窗 */
    openEdit(item, action) {
      const obj = Object.assign({}, item)
      const index = this.fieldList.findIndex((val) => {
        return val.label === item.label
      })
      this.activeIndex = index
      // console.log('index', index)
      if (obj) {
        this.$refs.fieldEdit.initForm(obj, action)
      }
      this.showEdit = true
    },
    /* 刷新表格 */
    reload() {
      // this.$refs.tableReg.reload({ page: 1 })
      this.getTemField()
    },
    reset() {
      // this.where = {}
      // this.$nextTick(() => {
      //   this.reload()
      // })
      this.where = {}
      this.getTemField()
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.id) }
          activityFormFieldApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
<style lang="less" scoped>
.left-wrap-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.add-field-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  .tips {
    margin-right: 10px;
  }
}
.selection-list {
  width: 100%;
  height: 500px;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
  // border: 1px solid #979797;
  // background: #f0eff4;
  .list-item {
    height: 115px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 5px rgba(222, 225, 230, 0.7);
    .item-left {
      width: 20px;
      background-color: #e5e5e5;
      font-size: 15px;
      .sortNumber {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        background-color: #c4c4c4;
        color: #fff;
      }
      .field-type {
        // padding: 5px 0;
        width: 100%;
        vertical-align: middle;
        color: #969696;
        text-align: center;
      }
    }
    .item-right {
      flex: 1;
      background-color: #fff;
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: space-around;
      .field-name {
        font-size: 20px;
        font-weight: bold;
        flex: 1;
      }
      .required {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      .circle-bg-default {
        background-color: #fff;
      }
      .circle-bg-active {
        background-color: #3c9cff;
      }
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        border-radius: 50%;
        height: 20px;
        margin-right: 10px;
        border: 1px solid #e4e4e4;
      }
    }
    .flex-box {
      display: flex;
      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #e4e4e4;
      height: 50%;
      &:last-child {
        border: none;
      }
      .edit-btn {
        padding: 2px 10px;
        font-size: 16px;
        border-radius: 5px;
        margin-right: 20px;
        border: 1px solid #a4a4a4;
        color: #6b6b6b;
        cursor: pointer;
      }
    }
  }
}
.field-item-def {
  border: 1px solid #979797;
  color: #9b9b9b;
}
.field-item-act {
  border: 1px solid #3ba8cb;
  color: #3ba8cb;
}
.field-item {
  padding: 5px 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
  .xsj {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #00a8f3 transparent; /*上下颜色 左右颜色*/
    border-width: 0 0 25px 25px;
    border-style: solid;

    // background-color: ;
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: -5px;
  }
}
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}

:deep(.ud-tool) {
  display: none;
}
</style>
