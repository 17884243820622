import request from '@/utils/request'

// api地址
const api = {
  all: '/frm/activitySign',
  save: '/frm/activitySign',
  update: '/frm/activitySign/update',
  delete: '/frm/activitySign/',
  deleteBatch: '/frm/activitySign/batch',
  page: '/frm/activitySign/page',
  export: '/frm/activitySign/export/',
  create: '/frm/activitySign/createSign/',
  downloadWXACode: '/frm/activitySign/downWXACode/',
  import: '/frm/activitySign/import/',
  nonePrizeSignUser: '/frm/activitySign/nonePrize'
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 导出活动签到表信息
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function exportActivityUserSign(id, params) {
  return new Promise((resolve, reject) => {
    request.get(api.export + id, {
      params: params,
      responseType: 'blob'
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function createUserSign(id) {
  return new Promise((resolve, reject) => {
    request.post(api.create + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 下载活动签到小程序二维码
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function downloadWXACode(id) {
  return new Promise((resolve, reject) => {
    request.get(api.downloadWXACode + id, {
      responseType: 'blob'
    }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 导入活动安排
 * @param data 参数
 * @returns {Promise<>}
 */
export function imports(id, data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return new Promise((resolve, reject) => {
    request.post(api.import + id, data, config
    ).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function nonePrizeSignUser(params) {
  return new Promise((resolve, reject) => {
    request.get(api.nonePrizeSignUser, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
