<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form :model="where" :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }">
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="活动名称:">
              <a-input v-model:value.trim="where.activityName" placeholder="请输入活动名称" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="活动类型:" name="activityType">
              <a-select v-model:value="where.activityType" placeholder="请选择活动类型" allow-clear>
                <a-select-option v-for="item in activityTypeList" :key="item.dictDataName" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="活动状态:" name="state">
              <a-select v-model:value="where.state" placeholder="请选择活动状态" allow-clear>
                <a-select-option v-for="item in activityStateList" :key="item.dictDataName" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table ref="table" row-key="activityId" :datasource="datasource" :columns="columns"
        v-model:selection="selection" :scroll="{ x: 'max-content' }">
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #modules="{ record }">
          <a-space>
            <a @click="openRegInfo(record)">报名信息</a>
            <a-divider type="vertical" />
            <a @click="openCheckInfo(record)">签到信息</a>
            <!-- <a-divider type="vertical" />
            <a @click="openCostInfo(record)">费用信息</a>
            <a-divider type="vertical" />
            <a @click="openSponsor(record)">赞助商</a> -->
            <!-- <a-divider type="vertical" />
            <a @click="openMaterial(record)">物资管理</a> -->
            <a-divider type="vertical" />
            <a @click="openLogInfo(record)">活动记录</a>            
          </a-space>
        </template>
        <template #pic="{ record }">
          <div class="image">
            <a-image :src="record.activityMainPic" :width="120" :height="90"></a-image>
          </div>
        </template>
        <template #actTime="{ record }">
          <a-space direction="vertical">
            <span v-if="record.actStartTime">开始: {{ record.actStartTime }}</span>
            <span v-if="record.actEndTime">结束: {{ record.actEndTime }}</span>
          </a-space>
        </template>
        <template #applyTime="{ record }">
          <a-space direction="vertical">
            <span v-if="record.startTime">开始: {{ record.startTime }}</span>
            <span v-if="record.endTime">结束: {{ record.endTime }}</span>
          </a-space>
        </template>
        <template #regList="{ record }">
          <a-space direction="vertical">
            <a-button type="primary" @click="openRegInfo(record)">报名信息</a-button>
            <a-button type="info" @click="openCheckInfo(record)">签到信息</a-button>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <!-- <a @click="openDetail(record)">详情</a> -->
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此分组吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <ActivityInfoEdit v-model:visible="showEdit" :data="current" @done="reload" />
    <!-- 详情弹窗 -->
    <ActivityInfoDetail v-model:visible="showDetail" :data="detailInfo" />
    <!-- 报名信息弹窗 -->
    <a-modal :width="1300" v-model:visible="showRegInfo" :confirm-loading="loading" :footer="null">
      <regInfo @done="reload" :activityId="currentId" :formId="formId"/>
    </a-modal>
    <a-modal :width="1300" v-model:visible="showCheckInfo" :confirm-loading="loading" :footer="null">
      <!-- 签到信息弹窗 -->
      <checkInfo @done="reload" :activityId="currentId" />
    </a-modal>
    <a-modal :width="1300" v-model:visible="showCostInfo" :confirm-loading="loading" :footer="null">
      <!-- 费用信息弹窗 -->
      <costInfo @done="reload" :activityId="currentId" />
    </a-modal>
    <!-- 活动记录 -->
    <logInfo :activityId="currentId" v-model:visible="showLogInfo" />
        
  </div>
</template>

<script>
import * as activityInfoApi from '@/api/activity/activityInfo.js'
import * as dictApi from '@/api/dict/dictData.js'
import ActivityInfoEdit from './index-edit.vue'
import ActivityInfoDetail from './index-detail.vue'
import regInfo from './reg/index.vue'
import checkInfo from './sign/index.vue'
import costInfo from './cost/index.vue'
import logInfo from './log/index.vue'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'grouptList',
  components: {
    PlusOutlined,
    DeleteOutlined,
    ActivityInfoEdit,
    ActivityInfoDetail,
    regInfo,
    checkInfo,
    costInfo,
    logInfo
  },
  data() {
    return {
      name: 'grouptList',
      isEditManaged: false,
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '活动主图',
          slots: {
            customRender: 'pic'
          }
        },
        {
          title: '活动状态',
          dataIndex: 'state',
          sorter: true
        },
        {
          title: '活动名称',
          dataIndex: 'activityName'
        },
        {
          title: '功能',
          slots: { customRender: 'modules' }
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {corpId:this.getCorpId()},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      showDetail: false,
      showRegInfo: false,
      showCheckInfo: false,
      showCostInfo: false,
      showLogInfo: false,
      // 当前编辑数据
      current: null,
      currentId: null,
      formId: null,
      // 编辑表单提交状态
      editLoading: false,
      activityStateList: [],
      activityTypeList: []
    }
  },
  mounted() {
    this.queryActivityState()
    this.queryActivityType()
  },
  methods: {
    datasource(option, callback) {
      activityInfoApi
        .page({
          ...this.where,
          order: 'desc',
          sort: 'createTime',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    // 字符串转数组
    strToArr(str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    // 数组转字符串
    arrToStr(arr) {
      if (arr.length > 0) {
        return arr.join(',')
      } else {
        return ''
      }
    },
    // /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    // /* 打开编辑弹窗 */
    openDetail(row) {
      this.detailInfo = row
      this.showDetail = true
    },
    // 打开报名信息
    openRegInfo(row) {
      this.currentId = row.activityId
      this.formId = row.formId
      this.showRegInfo = true
    },
    openCheckInfo(row) {
      this.currentId = row.activityId
      this.showCheckInfo = true
    },
    openCostInfo(row) {
      this.currentId = row.activityId
      this.showCostInfo = true
    },
    openLogInfo(row) {
      this.currentId = row.activityId
      this.showLogInfo = true
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    // /* 重置搜索 */
    reset() {
      const corpId = this.getCorpId()
      this.where = {corpId}
      this.$nextTick(() => {
        this.reload()
      })
    },
    // /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      activityInfoApi
        .deleteById(row.activityId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    // /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.activityId) }
          activityInfoApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    // /* 查询活动状态 */
    queryActivityState() {
      dictApi
        .getDictData({ dictCode: 'activityState' })
        .then((res) => {
          if (res.code === 0) {
            this.activityStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // /* 查询活动状态 */
    queryActivityType() {
      dictApi
        .getDictData({ dictCode: 'activityType' })
        .then((res) => {
          if (res.code === 0) {
            this.activityTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style  scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
