<template>
  <div>
    <a-modal
      :width="580"
      :visible="visible"
      :confirm-loading="loading"
      title="添加嘉宾"
      @update:visible="updateVisible"
      @ok="save"
      :body-style="{ paddingBottom: '8px' }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <a-form
            :model="form"
            :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
            :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
          >
            <a-form-item label="姓名" name="name">
              <a-input
                v-model:value="form.name"
                placeholder="请输入嘉宾姓名"
              ></a-input>
            </a-form-item>
            <a-form-item label="手机号" name="phone">
              <a-input
                v-model:value="form.phone"
                placeholder="请输入嘉宾手机号"
              ></a-input>
            </a-form-item>
            <a-form-item label="性别" name="sex">
              <a-select
                v-model:value="form.sex"
                showArrow
                placeholder="请选择性别"
              >
                <a-select-option
                  v-for="(item, index) in sexList"
                  :key="index"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataCode }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="分组" name="group">
              <a-select
                v-model:value="form.group"
                showArrow
                placeholder="请选择分组"
              >
                <a-select-option
                  v-for="(item, index) in sexList"
                  :key="index"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataCode }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import * as dictApi from '@/api/dict/dictData.js'
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {},
      sexList: []
    }
  },
  created() {
    this.querySexList()
  },
  methods: {
    save() {
      this.updateVisible(false)
    },
    querySexList() {
      dictApi
        .getDictData({ dictCode: 'sex' })
        .then((res) => {
          if (res.code === 0) {
            this.sexList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
